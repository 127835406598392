import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"



const ContactPage = () => {
    return (
        <Layout>
            <SEO title="Movies" />

        </Layout>
        
    )
    
}

export default ContactPage;